import React, { useContext, useState, useEffect } from "react";
import { GlobalState } from "../../GlobalState";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faBars,
  faUser,
  faCog,
  faPlus,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";

export default function Header() {
  const state = useContext(GlobalState);
  const [isLogged] = state.UserAPI.isLogged;
  const [isAdmin] = state.UserAPI.isAdmin;
  const [user] = state.UserAPI.user;
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const logoutUser = async () => {
    await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/logout`);
    localStorage.clear();
    window.location.href = "/login";
  };

  const handleNavClick = () => {
    setIsMobileMenuOpen(false);
  };

  const NavLinks = () => (
    <ul className="nav-links">
      <li onClick={handleNavClick}>
        {isAdmin ? (
          <Link to="/register">
            <FontAwesomeIcon icon={faUser} className="nav-icon" />
            <span>کسب‌وکارجدید</span>
          </Link>
        ) : (
          <Link to="/profile">
            <FontAwesomeIcon icon={faUser} className="nav-icon" />
            <span>حواله‌ها</span>
          </Link>
        )}
      </li>
      <li onClick={handleNavClick}>
        <Link to="/create_transfer">
          <FontAwesomeIcon icon={faPlus} className="nav-icon" />
          <span>حواله‌جدید</span>
        </Link>
      </li>
      <li onClick={handleNavClick}>
        <Link to="/add_collegue">
          <FontAwesomeIcon icon={faUserCog} className="nav-icon" />
          <span>همکار‌جدید</span>
        </Link>
      </li>
      <li onClick={handleNavClick}>
        <Link to="/settings">
          <FontAwesomeIcon icon={faCog} className="nav-icon" />
          <span>تنظیمات</span>
        </Link>
      </li>
    </ul>
  );

  const UserSection = () =>
    isLogged && (
      <div className="user-header">
        <Link to="/user_panel" className="user-info" onClick={handleNavClick}>
          <FontAwesomeIcon icon={faUser} className="user-icon" />
          <span className="user-name">{`${user.fname} ${user.lname}`}</span>
        </Link>
        <button className="logout-button" onClick={logoutUser}>
          <FontAwesomeIcon icon={faSignOutAlt} />
        </button>
      </div>
    );

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add("mobile-menu-open");
    } else {
      document.body.classList.remove("mobile-menu-open");
    }

    return () => {
      document.body.classList.remove("mobile-menu-open");
    };
  }, [isMobileMenuOpen]);

  return (
    <>
      <header className={isMobileMenuOpen ? "mobile-open" : ""}>
        <div className="header-top">
          <button
            className="mobile-menu-button"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-label="Toggle menu"
          >
            <FontAwesomeIcon icon={faBars} />
          </button>

          {/* Desktop Navigation */}
          <nav className="navbar desktop-nav">
            <NavLinks />
          </nav>

          <UserSection />
        </div>
      </header>

      {/* Separate Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="mobile-menu-overlay">
          <nav className="mobile-menu">
            <NavLinks />
          </nav>
        </div>
      )}

      <ToastContainer rtl={true} />
    </>
  );
}
