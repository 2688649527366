import React, { useState } from "react";

export default function FormFields(init) {
  const [Fields, setFields] = useState({ init });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...Fields, [name]: value });
  };

  return { Fields, handleChange };
}
