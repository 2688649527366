import React, { useContext, useEffect, useState, useRef } from "react";
import { GlobalState } from "../../../GlobalState";
import axios from "axios";
import { alertSuccess, alertError } from "../../../services/Alert";
import Loading from "../../Loading/Loading";
import { useLocation } from 'react-router-dom';
import numberWithCommas from "../../../services/NumbersInRials";

export default function AddAction({isVisible, onClose, data}) {
  const state = useContext(GlobalState);
  const [code, setCode] = useState("");
  const [token] = state.token
    const handleClose = () => {
    onClose();
  };

  const loginSubmit = async (e) => {
    e.preventDefault();
    try {
      const successLogin = await axios.post(
        "https://sekehsaeed.com/api/v1/user/login",
        { mobile: data.mobile, code: code }
      );
      if (successLogin.data.success) {
        localStorage.setItem("firstLogin", true);
        alertSuccess(successLogin.data.message, 2000);
        setTimeout(() => {
          window.location.href = "/profile";
        }, 500);
      }else{
        alertError(successLogin.data.message, 2000);
      }
    } catch (error) {
      alertError(error.response.data.message, 3000);
    }
  };

 useEffect(() => {
    if (inputRefs.current.length > 0) {
      inputRefs.current[0].focus();
    }
  }, []);

    // Create refs for the inputs
  const inputRefs = useRef([]);
  
  const handleInputChange = (e, i) => {
    const value = e.target.value;
    if (value.length === 1 && i < inputRefs.current.length - 1) {
      // Focus the next input when the current one is filled
      inputRefs.current[i + 1].focus();
    }

    // Concatenate all input values
    const newCode = inputRefs.current.map(input => input.value).join("");
    setCode(newCode);
  };

    const handleKeyUp = (e, i) => {
    if (e.key === "Backspace" && e.target.value === "" && i > 0) {
      // Focus the previous input when backspace is released in an empty input
      inputRefs.current[i - 1].focus();
    }
  };

  
  return (
    isVisible && (
      <div className="modal-login-container">
        <div className="modal-login">
          <a onClick={handleClose} className="close-button">
            &times;
          </a>
          <p>کد ارسال شده را وارد کنید</p>
          <form className="verify-login-form" style={{ direction: 'ltr' }}>
            {[...Array(5)].map((_, i) => (
              <input
                key={i}
                type="text"
                maxLength="1"
                ref={el => inputRefs.current[i] = el}
                onChange={(e) => handleInputChange(e, i)}
                onKeyUp={(e) => handleKeyUp(e, i)}
              />
            ))}
          </form>
            <button onClick={loginSubmit} type="submit">ورود</button>
        </div>
        <div className="overlay"></div>
      </div>
    )
  );
}
