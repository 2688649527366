import { useState, useEffect } from "react";
import axios from "axios";
import { alertSuccess, alertError } from "../../services/Alert";

export default function UserAPI(token) {
  const [isLogged, setIsLogged] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState([]);

  useEffect(() => {
    if (token) {
      const getUser = async () => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/infor`,
            {
              headers: { Authorization: token },
            }
          );
          setIsLogged(true);
          res.data.role === 1024 ? setIsAdmin(true) : setIsAdmin(false);
          setUser(res.data);
        } catch (error) {
          alertError("ایرادی رخ داده است", 3000);
        }
      };
      getUser();
    }
  }, [token]);

  // const addCart = async (product) => {
  //   if (!isLogged) {
  //     return alertError("برای خرید ابتدا وارد سایت شوید", 3000);
  //   }

  //   const check = cart.every((item) => {
  //     return item._id !== product._id;
  //   });

  //   if (check) {
  //     const res = await axios.patch(
  //       "http://localhost:4001/api/v1/user/addcart",
  //       { cart: [...cart, { ...product, quantity: 1 }] },
  //       { headers: { Authorization: token } }
  //     );
  //     if (res.data.status === "failed") {
  //       alertError(res.status.message, 3000);
  //     } else {
  //       setCart([...cart, { ...product, quantity: 1 }]);
  //       alertSuccess("محصول با موفقیت به سبد خرید اضافه شد", 3000);
  //     }
  //   }
  // };

  return {
    isLogged: [isLogged, setIsLogged],
    isAdmin: [isAdmin, setIsAdmin],
    // addCart: addCart,
    user: [user, setUser],
  };
}
