// HiddenComponent.jsx

import React, { useState, useContext } from "react";
import Collapsible from "../../../services/Collapsible";
import "./card.css";
import numberWithCommas from "../../../services/NumbersInRials";
import { GlobalState } from "../../../GlobalState";
import { alertSuccess, alertError } from "../../../services/Alert";
import axios from "axios";

const EndDialog = ({ isVisible, onClose, data }) => {
  const state = useContext(GlobalState);
  const handleClose = () => {
    onClose();
  };

  const [addedToSystem, setAddedToSysytem] = useState(data.addedToSystem);
  const [isFinished, setIsFinished] = useState(data.finished);
  const [token] = state.token;

  const submitFinish = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/transfer/finish/${data._id}`,
      {
        isAddedTosystem: addedToSystem,
        isFinished: isFinished,
      },
      {
        headers: { Authorization: token },
      }
    );
    if (response.data.success) {
      alertSuccess("تعییرات لحاظ شد", 2000);
      window.location.href = "/profile";
    }
  };

  return (
    isVisible && (
      <div className="modal-container">
        <div className="modal">
          <button onClick={handleClose} className="close-button">
            &times;
          </button>

          <div>
            <input
              type="checkbox"
              id="checkbox1"
              checked={addedToSystem}
              onChange={() => setAddedToSysytem(!addedToSystem)}
            />
            <label htmlFor="checkbox1">ثبت سیستم شد</label>
          </div>
          <div>
            <input
              type="checkbox"
              id="checkbox2"
              checked={isFinished}
              onChange={() => setIsFinished(!isFinished)}
            />
            <label htmlFor="checkbox2">اتمام حواله</label>
          </div>

          <div className="transfer-detail">
            <button type="submit" onClick={submitFinish}>
              تایید
            </button>
          </div>
        </div>
        <div className="overlay"></div>
      </div>
    )
  );
};

export default EndDialog;
