import React, { useContext, useEffect, useState, useRef } from "react";
import { GlobalState } from "../../../GlobalState";
import Loading from "../../Loading/Loading";
import finishedLogo from "../Card/well-done-smile.png";

import numberWithCommas from "../../../services/NumbersInRials";
import { Link } from "react-router-dom";
import InformationDialog from "./InformationDialog";
import EndDialog from "./EndDialog";
import EditDialog from "./EditDialog";
import AddAction from "../Transfer/AddAction";
import jalaliMoment from "jalali-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { alertSuccess, alertError } from "../../../services/Alert";

export default function Cards(data) {
  const state = useContext(GlobalState);
  const {
    name,
    mobile,
    cartNumber,
    price,
    date,
    actions,
    createdAt,
    ownerName,
    _id,
    payed,
    finished,
    addedToSystem,
    caption,
  } = data.transfer;
  const [isVisible, setIsVisible] = useState(false);
  const [actionDialog, setActionDialog] = useState(false);
  const [endDialog, setEndDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [copyToClipboardState, setCopyToClipboardState] = useState(false);

  const cardNumberRef = useRef(null);

  const copyToClipboard = () => {
    if (cardNumberRef.current) {
      cardNumberRef.current.select();
      document.execCommand("copy");
      alertSuccess("کپی شد", 2000);
    }
  };

  const handleButtonClick = () => {
    setIsVisible(true);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  const actionDialogButtonClick = () => {
    // New function to handle button click for AnotherCard
    setActionDialog(true);
  };

  const actionDialogClose = () => {
    // New function to handle close for AnotherCard
    setActionDialog(false);
  };

  const endDialogButtonClick = () => {
    // New function to handle button click for AnotherCard
    setEndDialog(true);
  };

  const endDialogClose = () => {
    // New function to handle close for AnotherCard
    setEndDialog(false);
  };

  const editDialogButtonClick = () => {
    // New function to handle button click for AnotherCard
    setEditDialog(true);
  };

  const editDialogClose = () => {
    // New function to handle close for AnotherCard
    setEditDialog(false);
  };

  return (
    <>
      {/* <img src={finishedLogo} alt="cart" width="100" /> */}
      <div className={`card ${finished ? "finished" : ""}`}>
        <div className={finished ? "decagon-badge" : ""}></div>
        <div>
          <strong>{name} </strong>
        </div>
        <div className="transfer-card">
          <p>صاحب‌ حساب </p> {ownerName}
        </div>
        {/* <div className="transfer-card">شماره کارت: {cartNumber}</div> */}
        <div className="transfer-card">
          <p>تاریخ ایجاد </p> {convertToJalali(createdAt)}
        </div>
        <div
          className="transfer-card"
          onClick={copyToClipboard}
          style={{ cursor: "pointer" }}
        >
          <p>شماره کارت </p>{" "}
          <span>
            <FontAwesomeIcon
              className="material-icons"
              id="copy-icon"
              icon="fa-solid fa-clipboard"
            />
            {cartNumber}
          </span>
        </div>
        <textarea
          ref={cardNumberRef}
          value={cartNumber}
          style={{ position: "absolute", left: "-9999px" }} // Hide the textarea off-screen
          readOnly
        />
        <div className="transfer-card">
          <p> توضیحات </p> {caption}
        </div>
        <div className="card-price-date-container">
          <span className="clear-payment-time">
            زمان تسویه {convertToJalaliWithoutHours(date)}
          </span>
          <div className="price-section">
            <span>{numberWithCommas(price)}</span>
            <br />
            <span className="rest-of-transfer">
              {numberWithCommas(price - payed)} <p>مانده</p>
            </span>
            <div className="information-card-container">
              <span className="information-card">
                <p>{finished ? "خاتمه یافته" : "جاری"}</p>
              </span>
              <span className="information-card">
                <p>{addedToSystem ? "ثبت شده" : "ثبت نشده"}</p>
              </span>
              <span className="information-card">
                <p>{actions.length} واریزی </p>
              </span>
            </div>
          </div>
        </div>

        <div className="transfer-detail-container">
          <div className="edit-transfer-detail">
            <EditDialog
              isVisible={editDialog}
              onClose={editDialogClose}
              data={data.transfer}
            />
            <button onClick={editDialogButtonClick} id="edit-button">
              ویرایش
            </button>
          </div>

          <div children="transfer-detail">
            <InformationDialog
              isVisible={isVisible}
              onClose={handleClose}
              data={data.transfer}
            />
            <AddAction
              isVisible={actionDialog}
              onClose={actionDialogClose}
              data={data.transfer}
            />
            <EndDialog
              isVisible={endDialog}
              onClose={endDialogClose}
              data={data.transfer}
            />
            <button onClick={endDialogButtonClick} id="end-button">
              اتمام
            </button>
            <button onClick={actionDialogButtonClick} id="action-button">
              واریز
            </button>
            <button onClick={handleButtonClick} id="infor-button">
              جزئیات
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

function convertToJalali(gregorianDate) {
  const jalaliDate = jalaliMoment(gregorianDate).format(
    "jYYYY/jMM/jDD HH:mm:ss"
  );
  // const jalaliDate = jalaliMoment(gregorianDate).format('jYYYY/jMM/jDD');
  return jalaliDate;
}
function convertToJalaliWithoutHours(gregorianDate) {
  // const jalaliDate = jalaliMoment(gregorianDate).format('jYYYY/jMM/jDD HH:mm:ss');
  const jalaliDate = jalaliMoment(gregorianDate).format("jYYYY/jMM/jDD");
  return jalaliDate;
}
