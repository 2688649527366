import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../../../GlobalState";
import axios from "axios";
import { alertSuccess, alertError } from "../../../services/Alert";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import BarChartIcon from "@mui/icons-material/BarChart";
import jalaliMoment from "jalali-moment";

export default function UserPanel() {
  const state = useContext(GlobalState);
  const [company, setCompany] = useState([]);
  const [token] = state.token;
  const [user] = state.UserAPI.user;

  useEffect(() => {
    const getUser = async () => {
      try {
        if (user?.companyId) {
          const res = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get_company/${user.companyId}`,
            {
              headers: { Authorization: token },
            }
          );
          setCompany(res.data.company);
        }
      } catch (error) {
        alertError("ایرادی رخ داده است", 3000);
      }
    };
    getUser();
  }, [token, user?.companyId]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h2" gutterBottom align="center">
        پنل کاربری
      </Typography>

      <Grid container spacing={3}>
        {/* Personal Info Card */}
        <Grid item xs={12} md={4}>
          <Card elevation={3}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <PersonIcon sx={{ mr: 1, color: "primary.main" }} />
                <Typography variant="h6" component="h3">
                  اطلاعات شخصی
                </Typography>
              </Box>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="body1" gutterBottom>
                {`${user.fname} ${user.lname}`}
              </Typography>
              <Typography variant="body1">{`${user.mobile}`}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Company Info Card */}
        <Grid item xs={12} md={4}>
          <Card elevation={3}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <BusinessIcon sx={{ mr: 1, color: "primary.main" }} />
                <Typography variant="h6" component="h3">
                  اطلاعات کسب و کار
                </Typography>
              </Box>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="body1" gutterBottom>
                نام کسب و کار: {company.companyName}
              </Typography>
              <Typography variant="body1" gutterBottom>
                شماره همراه: {company.mobile}
              </Typography>
              <Typography variant="body1" gutterBottom>
                ساخته شد در: {convertToJalali(company.createdAt)}
              </Typography>
              <Typography variant="body1">
                فعال تا: {convertToJalali(company.expireDate)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Statistics Card */}
        <Grid item xs={12} md={4}>
          <Card elevation={3}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <BarChartIcon sx={{ mr: 1, color: "primary.main" }} />
                <Typography variant="h6" component="h3">
                  آمار و اطلاعات
                </Typography>
              </Box>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="body1">به زودی</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

function convertToJalali(gregorianDate) {
  // const jalaliDate = jalaliMoment(gregorianDate).format('jYYYY-jMM-jDD HH:mm:ss');
  const jalaliDate = jalaliMoment(gregorianDate).format("jYYYY/jMM/jDD");
  return jalaliDate;
}
