import React from "react";
import { CircularProgress, Box } from "@mui/material";
import "./loading.css";

export default function Loading() {
  return (
    <Box
      className="load-page"
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      <CircularProgress size={60} thickness={4} />
    </Box>
  );
}
