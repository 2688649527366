import React, { useContext } from "react";
import axios from "axios";
import { GlobalState } from "../../../GlobalState";
import useFormFields from "../../hooks/FormFields";
import { alertSuccess, alertError } from "../../../services/Alert";

export default function ProfileUser() {
  const state = useContext(GlobalState);
  const [user] = state.UserAPI.user;
  const { Fields, handleChange } = useFormFields({
    fname: "",
    lname: "",
    email: "",
    address: "",
    mobile: "",
  });

  const editSubmit = async (e) => {
    e.preventDefault();
    try {
      const successEdit = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/${user._id}/edit_info`,
        {
          ...Fields,
        }
      );
      if (successEdit) {
        alertSuccess(successEdit.data.message, 2800);
      }
    } catch (error) {
      alertError(error.response.data.message, 4000);
    }
  };

  return (
    <div className="profile-main-container">
      <h1>adminnnnn</h1>
    </div>
  );
}
