import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Loading from "../../Loading/Loading";
import { alertSuccess, alertError } from "../../../services/Alert";
import EndVerifyLogin from "./VerifyLogin"
import Cookies from 'js-cookie';

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [verifyLogin, setVerifyLogin] = useState(false);
  const [captchaImage, setCaptchaImage] = useState('');
  const [captchaValue, setCaptchaValue] = useState('')
  const [user, setUser] = useState({
    mobile: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const verifyDialogButtonClick = () => { // New function to handle button click for AnotherCard
    setVerifyLogin(true);
  };

  const verifyDialogClose = () => { // New function to handle close for AnotherCard
    setVerifyLogin(false);
  };

  const captchaValidation = () =>{
    const originalCaptcha = getCookie('captcha');
    if(originalCaptcha != user.captcha){
        return true
        // alertError('عبارت امنیتی اشتباه است', 1000);
      }
    return false
  }

  const loginSubmit = async (e) => {
    e.preventDefault();
    try {
      const successLogin = await axios.post(
        "https://sekehsaeed.com/api/v1/user/login",
        { mobile: user.mobile, password: user.password }
      );
      if (successLogin.data.success) {
        localStorage.setItem("firstLogin", true);
        alertSuccess(successLogin.data.message, 2000);
        setTimeout(() => {
          window.location.href = "/profile";
        }, 500);
      }else{
        alertError(successLogin.data.message, 2000);
      }
    } catch (error) {
      alertError(error.response.data.message, 3000);
    }
  };

const fetchCaptcha = async () => {
    try {
      const response = await fetch('https://sekehsaeed.com/api/v1/user/captcha', {
        method: 'GET',
        credentials: 'include', // Include cookies in the request   
      });
      const imageBlob = await response.blob();
      const imageObjectURL = URL.createObjectURL(imageBlob);
      setCaptchaImage(imageObjectURL)
    }
    catch (error) {
      console.error('Error fetching captcha:', error)
  }};

useEffect(() => {
    fetchCaptcha()
    },
  []);

function getCookie(key) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <div className="login-page">
            <form onSubmit={loginSubmit}>
              <h2>ورود</h2>
              <input
                type="text"
                name="mobile"
                required
                placeholder="شماره تلفن"
                value={user.mobile}
                onChange={handleChange}
              />

              <input
                type="password"
                name="password"
                required
                placeholder="رمزعبور"
                value={user.password}
                onChange={handleChange}
              />

        

              <div className="captcha-container">
              <input
                type="text"
                name="captcha"
                required
                placeholder="عبارت امنیتی"
                value={user.captcha}
                onChange={handleChange}
              />
                <img onClick={fetchCaptcha} src={captchaImage} />
              </div>

              <div className="row">
                <EndVerifyLogin isVisible={verifyLogin} onClose={verifyDialogClose} data={user} />
                <button type="submit">ورود</button>
                {/* <Link to="/register">ثبت نام</Link> */}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
