import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../../../GlobalState";
import axios from "axios";
import { alertSuccess, alertError } from "../../../services/Alert";
import Loading from "../../Loading/Loading";
import "react-datepicker/dist/react-datepicker.css";
import { RangePicker } from "react-jalali-datepicker";
import DatePicker from "react-datepicker2";
import jalaliMoment from "jalali-moment";
import "react-datepicker/dist/react-datepicker.css";
// import "react-datepicker2/dist/react-datepicker2.min.css";

export default function Products() {
  const state = useContext(GlobalState);
  const [token] = state.token;
  const [user] = state.UserAPI.user;

  const [collegue, setCollegue] = useState({
    mobile: "",
    fname: "",
    lname: "",
    password: "",
    companyId: user.companyId,
    createdById: user._id,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCollegue({ ...collegue, [name]: value });
  };

  const submitTransfer = async (e) => {
    e.preventDefault();
    try {
      const successTransferSubmit = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/add_collegue`,
        { ...collegue },
        {
          headers: { Authorization: token },
        }
      );
      if (successTransferSubmit.data.success) {
        alertSuccess(successTransferSubmit.data.message, 2500);
        setTimeout(() => {
          window.location.href = "/profile";
        }, 2500);
      }
    } catch (error) {
      alertError(error.response.data.message, 3000);
    }
  };

  return (
    <>
      <div className="create-transfer-form-container">
        <form className="simple-form" onSubmit={submitTransfer}>
          <h2>افزودن همکار</h2>
          <input
            type="text"
            name="fname"
            required
            placeholder="نام"
            value={collegue.fname}
            onChange={handleChange}
          />

          <input
            type="text"
            name="lname"
            required
            placeholder="نام خانوادگی"
            value={collegue.lname}
            onChange={handleChange}
          />

          <input
            type="text"
            name="mobile"
            required
            placeholder="تلفن همراه"
            value={collegue.mobile}
            onChange={handleChange}
          />

          <input
            type="password"
            name="password"
            required
            placeholder="رمزعبور"
            value={collegue.password}
            onChange={handleChange}
          />

          <div className="row">
            <button type="submit">ثبت</button>
          </div>
        </form>
      </div>
    </>
  );
}
