import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../../../GlobalState";
import axios from "axios";
import { alertSuccess, alertError } from "../../../services/Alert";
import Loading from "../../Loading/Loading";
import "react-datepicker/dist/react-datepicker.css";
import { RangePicker } from "react-jalali-datepicker";
import DatePicker from "react-datepicker2";
import jalaliMoment from "jalali-moment";
import "react-datepicker/dist/react-datepicker.css";
import numberWithCommas from "../../../services/NumbersInRials";
import { useSocket } from "../../../context/SocketContext";

export default function Products() {
  const state = useContext(GlobalState);
  const [token] = state.token;
  const [user] = state.UserAPI.user;
  const socket = useSocket();

  const [transfer, setTransfer] = useState({
    mobile: "",
    caption: "",
    name: "",
    cartNumber: "",
    price: "",
    ownerName: "",
    companyId: user.companyId,
    createdBy: user._id,
    date: jalaliMoment(),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTransfer({ ...transfer, [name]: value });
  };

  const handleDateChange = (time) => {
    setTransfer({ ...transfer, date: time });
  };

  const submitTransfer = async (e) => {
    e.preventDefault();
    try {
      console.log("Submitting transfer with data:", {
        ...transfer,
        actorId: user._id,
        companyId: user.companyId,
      });

      const successTransferSubmit = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/transfer/create`,
        {
          ...transfer,
          actorId: user._id,
        },
        {
          headers: { Authorization: token },
        }
      );

      console.log("Create response:", successTransferSubmit.data);

      if (successTransferSubmit.data.success) {
        alertSuccess(successTransferSubmit.data.message, 1000);
        setTimeout(() => {
          window.location.href = "/profile";
        }, 2500);
      }
    } catch (error) {
      console.error("Create error:", error);
      alertError(error.response?.data?.message || "An error occurred", 3000);
    }
  };

  return (
    <>
      <div className="create-transfer-form-container">
        <form className="simple-form" onSubmit={submitTransfer}>
          <h2>افزودن حواله</h2>
          <input
            type="text"
            name="name"
            required
            placeholder="نام‌ و نام‌خانوادگی‌ همکار"
            value={transfer.name}
            onChange={handleChange}
          />

          <input
            type="text"
            name="mobile"
            placeholder="تلفن همراه"
            value={transfer.mobile}
            onChange={handleChange}
          />
          <input
            type="text"
            name="cartNumber"
            required
            placeholder="شماره کارت یا شبا یا حساب"
            value={transfer.cartNumber}
            onChange={handleChange}
          />
          <input
            type="text"
            name="ownerName"
            required
            placeholder="نام‌صاحب‌حساب"
            value={transfer.ownerName}
            onChange={handleChange}
          />
          <input
            type="text"
            name="caption"
            required
            placeholder="توضیحات"
            value={transfer.caption}
            onChange={handleChange}
          />
          <DatePicker
            value={transfer.date}
            onChange={handleDateChange}
            isGregorian={false}
            placeholder="ددلاین واریز"
            className="date-picker"
          />
          {/* <RangePicker name='date' isJalaali={true} onChangeRange={handleChange}/> */}
          <input
            type="text"
            name="price"
            required
            placeholder="مبلغ حواله "
            value={numberWithCommas(transfer.price)}
            onChange={handleChange}
          />

          <div className="row">
            <button type="submit">ثبت</button>
          </div>
        </form>
      </div>
    </>
  );
}
