import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { GlobalState } from "../../../GlobalState";
import useFormFields from "../../hooks/FormFields";
import { alertSuccess, alertError } from "../../../services/Alert";
import Card from "../Card/Card";
import Loading from "../../Loading/Loading";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker2";
import jalaliMoment from "jalali-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

export default function ProfileUser() {
  const state = useContext(GlobalState);
  const [token] = state.token;
  const [user] = state.UserAPI.user;
  const [transfers, setTransfers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState("createdAt: -1");
  const [dateFilter, setDateFilter] = useState({
    // date: jalaliMoment(),
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState({
    name: "",
  });

  const transferDeadLine = () => {
    setSort("date");
  };
  const created = () => {
    setSort("createdAt");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchInput({ ...searchInput, [name]: value });
  };

  const clearDateFilter = () => {
    setDateFilter({});
    // This will trigger a re-render due to state change
  };

  const handleDateChange = (time) => {
    setDateFilter({ date: time });
  };

  const companyId = user.companyId;

  const fetchTransfers = async () => {
    try {
      let url = `${process.env.REACT_APP_BACKEND_URL}/api/v1/transfer/transfers/${companyId}?page=${currentPage}&limit=9`;
      if (searchInput.name.length >= 1) {
        url += `&name=${searchInput.name}`;
      }
      if (dateFilter.date) {
        url += `&dateFilter=${dateFilter.date}`;
      }
      const res = await axios.get(url, {
        headers: { Authorization: token },
      });

      if (res.data.success) {
        setTransfers(res.data.transfers);
        setLoading(false);
      }
    } catch (error) {
      alertError("ایرادی رخ داده است", 3000);
    }
  };

  useEffect(() => {
    if (token && companyId) {
      // Initial fetch
      fetchTransfers();
    }
  }, [token, companyId, sort, searchInput.name, currentPage, dateFilter]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="filter-navbar-container">
            <ul className="filter-navbar">
              <input
                type="text"
                name="name"
                placeholder="جست و جو..."
                value={searchInput.name}
                onChange={handleChange}
              />

              <div className="date-picker-container">
                <DatePicker
                  value={dateFilter.date}
                  onChange={handleDateChange}
                  isGregorian={false}
                  placeholder="فیلتر تاریخ"
                  className="datepickerstyle"
                />
                {dateFilter.date && (
                  <button onClick={clearDateFilter} className="clear-date-btn">
                    <p>حذف فیلتر</p>
                  </button>
                )}
              </div>
            </ul>
          </div>
          <div className="card-grid">
            {transfers.map((transfer, index) => (
              <Card key={index} transfer={transfer} />
            ))}
          </div>
          <div className="pagination-style">
            <button
              className="pagination-button"
              onClick={() => {
                setCurrentPage(currentPage - 1);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              disabled={currentPage === 1}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                className="pagination-icon"
              />
              صفحه قبل
            </button>
            <button
              className="pagination-button"
              onClick={() => {
                setCurrentPage(currentPage + 1);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              صفحه بعد
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="pagination-icon"
              />
            </button>
          </div>
        </>
      )}
    </>
  );
}

function myFunction() {
  document.getElementById("myDropdown1").classList.toggle("show");
}
function myFunction2() {
  document.getElementById("myDropdown2").classList.toggle("show");
}
