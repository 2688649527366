// HiddenComponent.jsx
import React, { useState, useEffect } from "react";
import "./card.css";
import numberWithCommas from "../../../services/NumbersInRials";
import jalaliMoment from "jalali-moment";
import EditActionDialog from "./EditActionDialog";
import DeleteActionDialog from "./DeleteActionDialog";
import ConfirmDialog from "./ConfirmDialog";

const SingleCard = ({ isVisible, onClose, data }) => {
  const [editDialog, setEditDialog] = useState(false);
  const [editedAction, setEditedAction] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteAction, setDeleteAction] = useState(null);
  const [endDialog, setEndDialog] = useState(false);

  // Handle body scroll lock
  useEffect(() => {
    if (isVisible) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isVisible]);

  const handleClose = () => {
    onClose();
  };

  const actionDialogButtonClick = (action) => {
    setEditedAction(action);
    setEditDialog(true);
  };

  const deleteActionDialog = (action) => {
    setDeleteAction(action);
    setDeleteDialog(true);
  };

  const actionDialogClose = () => {
    setEditedAction(null);
    setEditDialog(false);
  };

  const endDialogButtonClick = () => {
    setEndDialog(true);
  };

  const endDialogClose = () => {
    setEndDialog(false);
  };

  const renderTableContent = () => {
    if (!data.actions || data.actions.length === 0) {
      return <div className="empty-table-message">هیچ واریزی ثبت نشده است</div>;
    }

    return (
      <div style={{ overflowX: "auto" }}>
        <table className="actions-table">
          <caption>لیست واریزی‌ها</caption>
          <thead>
            <tr>
              <th>مرحله</th>
              <th>مبلغ‌واریزی</th>
              <th>واریز‌کننده</th>
              <th>توضیحات</th>
              <th>تاریخ‌واریز</th>
              <th>ثبت‌شده‌از‌اکانت</th>
              <th>مستندات</th>
              <th>عملیات</th>
            </tr>
          </thead>
          <tbody>
            {data.actions.map((action, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{numberWithCommas(action.price ?? 0)}</td>
                <td>{action.senderName ?? "N/A"}</td>
                <td>{action.caption ?? "N/A"}</td>
                <td>{convertToJalali(action.date ?? new Date())}</td>
                <td>{action.userName ?? "N/A"}</td>
                <td>
                  {action.image?.map((img, idx) => (
                    <a
                      key={idx}
                      href={`https://sekehsaeed.com/${img}`}
                      download={`image_${idx}`}
                      className="download-link"
                    >
                      تصویر {idx + 1}
                    </a>
                  ))}
                </td>
                <td>
                  <button
                    onClick={() => actionDialogButtonClick(action)}
                    className="edit-button"
                  >
                    ویرایش
                  </button>
                  <button
                    onClick={() => deleteActionDialog(action)}
                    className="edit-button deleteActionBtn"
                  >
                    حذف
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    isVisible && (
      <div className="modal-container">
        <div className="information-modal">
          <div className="information-modal-head">
            <button onClick={handleClose} className="close-button">
              ✕
            </button>
            <h2>همکار: {data.name}</h2>

            <div className="data-grid">
              <div className="data-item">
                <p className="inforTitle">تلفن‌همراه</p>
                <span>{data.mobile}</span>
              </div>
              <div className="data-item bankAcc">
                <p className="inforTitle">شماره‌حساب</p>
                <span>{data.cartNumber}</span>
              </div>
              <div className="data-item ownerName">
                <p className="inforTitle">صاحب‌‌حساب</p>
                <span>{data.ownerName}</span>
              </div>
              <div className="data-item totalPriceHavale">
                <p className="inforTitle">مبلغ‌کل حواله</p>
                <span>{numberWithCommas(data.price)}</span>
              </div>
              <div className="data-item payedPrice">
                <p className="inforTitle">مجموع‌واریزی</p>
                <span>{numberWithCommas(data.payed)}</span>
              </div>
              <div className="data-item remainsPrice">
                <p className="inforTitle">مبلغ‌باقیمانده</p>
                <span>{numberWithCommas(data.price - data.payed)}</span>
              </div>
            </div>

            <button onClick={endDialogButtonClick} id="delete-transfer-button">
              حذف حواله
            </button>
          </div>

          <div className="modal-content">{renderTableContent()}</div>

          {endDialog && (
            <ConfirmDialog
              isVisible={endDialog}
              onClose={endDialogClose}
              data={data}
            />
          )}

          {editedAction && (
            <EditActionDialog
              isVisible={editDialog}
              onClose={actionDialogClose}
              data={editedAction}
              _id={data._id}
            />
          )}

          {deleteAction && (
            <DeleteActionDialog
              isVisible={deleteDialog}
              onClose={() => setDeleteAction(false)}
              data={deleteAction}
              transfer={data}
              _id={data._id}
            />
          )}
        </div>
        <div className="overlay" onClick={handleClose}></div>
      </div>
    )
  );
};

function convertToJalali(gregorianDate) {
  return jalaliMoment(gregorianDate).format("jYYYY/jMM/jDD" + " HH:mm:ss");
}

export default SingleCard;
