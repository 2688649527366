import React, { useContext } from "react";
import ProfileUser from './ProfileUser'
import ProfileAdmin from './ProfileAdmin'
import { GlobalState } from "../../../GlobalState";

export default function Profile() {
  const state = useContext(GlobalState);
  const [isAdmin] = state.UserAPI.isAdmin;

  return (
    isAdmin ?
    <ProfileAdmin /> :
    <ProfileUser />
  )
}
