import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../../../GlobalState";
import axios from "axios";
import { alertSuccess, alertError } from "../../../services/Alert";
import Loading from "../../Loading/Loading";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker2";
import jalaliMoment from "jalali-moment";
import "react-datepicker/dist/react-datepicker.css";
import numberWithCommas from "../../../services/NumbersInRials";

export default function AddAction({ isVisible, onClose, data }) {
  const state = useContext(GlobalState);
  const [token] = state.token;
  const [user] = state.UserAPI.user;
  const handleClose = () => {
    onClose();
  };

  const [transfer, setTransfer] = useState({
    mobile: data.mobile,
    caption: data.caption,
    name: data.name,
    price: data.price,
    cartNumber: data.cartNumber,
    date: jalaliMoment(data.date),
    companyId: user.companyId,
    userId: user._id,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTransfer({ ...transfer, [name]: value });
  };

  const handleDateChange = (time) => {
    setTransfer({ ...transfer, date: time });
  };

  const submitTransfer = async (e) => {
    e.preventDefault();
    try {
      const successTransferSubmit = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/transfer/update/${data._id}`,
        { ...transfer, userId: user._id, companyId: user.companyId },
        {
          headers: { Authorization: token },
        }
      );
      if (successTransferSubmit.data.success) {
        alertSuccess(successTransferSubmit.data.message, 2500);
        setTimeout(() => {
          window.location.href = "/profile";
          // handleClose()
        }, 500);
      }
    } catch (error) {
      alertError(error.response.data.message, 3000);
    }
  };

  return (
    isVisible && (
      <div className="create-transfer-form-container">
        <div className="modal-container">
          <div className="modal fixed-center">
            <button onClick={handleClose} className="close-button">
              &times;
            </button>
            <form className="simple-form" onSubmit={submitTransfer}>
              <h2>فرم ویرایش حواله</h2>
              <input
                type="text"
                name="name"
                required
                placeholder="نام و نام خواندگی"
                value={transfer.name}
                onChange={handleChange}
              />

              <input
                type="text"
                name="mobile"
                required
                placeholder="تلفن همراه"
                value={transfer.mobile}
                onChange={handleChange}
              />
              <input
                type="text"
                name="caption"
                placeholder="توضیحات"
                value={transfer.caption}
                onChange={handleChange}
              />
              <input
                type="text"
                name="cartNumber"
                placeholder="شماره حساب یا شبا یا کارت"
                value={transfer.cartNumber}
                onChange={handleChange}
              />
              <DatePicker
                value={transfer.date}
                onChange={handleDateChange}
                isGregorian={false}
                placeholder="ددلاین واریز"
                className="date-picker"
              />
              <input
                type="text"
                name="price"
                required
                placeholder="مبلغ واریزی "
                value={numberWithCommas(transfer.price)}
                onChange={handleChange}
              />

              <div className="row">
                <button type="submit">ویرایش</button>
              </div>
            </form>
          </div>
          <div className="overlay"></div>
        </div>
      </div>
    )
  );
}
