import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../../../GlobalState";
import axios from "axios";
import { alertSuccess, alertError } from "../../../services/Alert";
import Loading from "../../Loading/Loading";
import jalaliMoment from "jalali-moment";

export default function Settings() {
  const state = useContext(GlobalState);
  const [collegues, setCollegues] = useState([]);
  const [token] = state.token;
  const [user] = state.UserAPI.user;

  const deactiveCollegue = async (collegueId, isActive) => {
    try {
      const status = !isActive;
      const res = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/deactiveCollegue/${collegueId}`,
        { userId: user._id, status: status },
        {
          headers: { Authorization: token },
        }
      );

      if (res.data.success) {
        alertSuccess(res.data.message, 1000);
        // Update the state (collegues) after successful API call
        const updatedCollegues = collegues.map((collegue) =>
          collegue._id === collegueId
            ? { ...collegue, isActive: status }
            : collegue
        );
        setCollegues(updatedCollegues);
      } else {
        alertError(res.data.message);
      }
    } catch (error) {
      alertError("ایرادی رخ داده است", 3000);
    }
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        if (user?.companyId) {
          const res = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/collegues/${user.companyId}`,
            {
              headers: { Authorization: token },
            }
          );
          setCollegues(res.data.items);
        }
      } catch (error) {
        alertError("ایرادی رخ داده است", 3000);
      }
    };
    getUser();
  }, [token, user?.companyId]);

  return (
    <>
      <div className="collegues-container">
        <div style={{ overflowX: "auto" }}>
          <table>
            <thead>
              <tr className="collegues-head-card">
                <th>نام</th>
                <th>نام‌خانوادگی</th>
                <th>شماره‌موبایل</th>
                <th>تاریخ‌ایجاد</th>
                <th>فعالسازی</th>
              </tr>
            </thead>
            <tbody>
              {collegues.map((collegue, index) => (
                <tr key={index} className="collegues-card">
                  <td>{collegue.fname}</td>
                  <td>{collegue.lname}</td>
                  <td>{collegue.mobile}</td>
                  <td>{convertToJalali(collegue.createdAt)}</td>
                  <td>
                    <button
                      className={
                        collegue.isActive
                          ? "activeCollegue"
                          : "deactivedCollegue"
                      }
                      onClick={() =>
                        deactiveCollegue(collegue._id, collegue.isActive)
                      }
                    >
                      {collegue.isActive ? "فعال" : "غیر‌فعال"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

function convertToJalali(gregorianDate) {
  // const jalaliDate = jalaliMoment(gregorianDate).format('jYYYY-jMM-jDD HH:mm:ss');
  const jalaliDate = jalaliMoment(gregorianDate).format("jYYYY/jMM/jDD");
  return jalaliDate;
}
