import React, { useContext, useEffect, useRef } from "react";
import { GlobalState } from "../../../GlobalState";
import axios from "axios";
import { alertSuccess, alertError } from "../../../services/Alert";
import { useSocket } from "../../../context/SocketContext";
import numberWithCommas from "../../../services/NumbersInRials";

export default function ConfirmDialog({ isVisible, onClose, data, transfer }) {
  const state = useContext(GlobalState);
  const [token] = state.token;
  const [user] = state.UserAPI.user;

  const handleClose = () => {
    onClose();
  };

  const submitDelete = async (e) => {
    e.preventDefault();
    try {
      console.log("Submitting delete with data:", {
        ...data,
        actorId: user._id,
        companyId: user.companyId,
      });

      const successTransferSubmit = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/transfer/deleteAction/${transfer._id}`,
        {
          ...data,
          actorId: user._id,
          companyId: user.companyId,
        },
        {
          headers: { Authorization: token },
        }
      );

      console.log("Delete response:", successTransferSubmit.data);

      if (successTransferSubmit.data.success) {
        alertSuccess(successTransferSubmit.data.message, 2500);
        setTimeout(() => {
          window.location.href = "/profile";
        }, 500);
      }
    } catch (error) {
      console.error("Delete error:", error);
      alertError(error.response?.data?.message || "An error occurred", 3000);
    }
  };

  return (
    isVisible && (
      <div className="create-transfer-form-container">
        <div className="modal-container">
          <div className="modal">
            <button onClick={handleClose} className="close-button">
              &times;
            </button>
            <p>
              از حذف واریز با‌ واریزکننده{" "}
              <span className="highlight-text">{data.senderName}</span> اطمینان
              دارید؟
            </p>
            <p>مبغ‌واریزی: {numberWithCommas(data.price)} تومان</p>
            <button onClick={submitDelete} className="confirm-buttons">
              بله
            </button>
            <button onClick={handleClose} id="confirm-delete-buttun">
              خیر
            </button>
          </div>
          <div className="overlay"></div>
        </div>
      </div>
    )
  );
}
