import React, { useContext, useEffect, useState, useRef } from "react";
import { GlobalState } from "../../../GlobalState";
import axios from "axios";
import { alertSuccess, alertError } from "../../../services/Alert";
import numberWithCommas from "../../../services/NumbersInRials";

export default function AddAction({ isVisible, onClose, data }) {
  const state = useContext(GlobalState);
  const [token] = state.token;
  const [user] = state.UserAPI.user;
  const [imageFiles, setImageFiles] = useState([]);

  const handleClose = () => {
    onClose();
  };

  const [transfer, setTransfer] = useState({
    mobile: data.mobile,
    caption: "",
    name: data.name,
    price: "",
    companyId: user.companyId,
    senderName: "",
    userId: user._id,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTransfer({ ...transfer, [name]: value });
  };

  const handleChangeImage = (e) => {
    setImageFiles([...e.target.files]);
  };

  const submitTransfer = async (e) => {
    e.preventDefault();
    try {
      console.log("Submitting transfer with data:", {
        ...transfer,
        actorId: user._id,
        companyId: user.companyId,
      });

      const formData = new FormData();
      Object.keys(transfer).forEach((key) =>
        formData.append(key, transfer[key])
      );
      imageFiles.forEach((file, index) => {
        formData.append(`image${index}`, file);
      });

      // Add actorId and companyId to formData
      formData.append("actorId", user._id);
      formData.append("companyId", user.companyId);

      const successTransferSubmit = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/transfer/action/${data._id}`,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (successTransferSubmit.data.success) {
        handleClose();
        alertSuccess(successTransferSubmit.data.message, 2500);
        setTimeout(() => {
          window.location.href = "/profile";
        }, 2500);
      }
    } catch (error) {
      console.error("Transfer error:", error);
      alertError(error.response?.data?.message || "An error occurred", 3000);
    }
  };

  return (
    isVisible && (
      <div className="create-transfer-form-container">
        <div className="modal-container">
          <div className="modal-action centered-modal">
            <button onClick={handleClose} className="close-button">
              &times;
            </button>
            <form
              className="simple-form"
              onSubmit={submitTransfer}
              encType="multipart/form-data"
            >
              <h2>فرم واریز</h2>
              <input
                type="text"
                name="senderName"
                required
                placeholder="نام‌ واریزکننده"
                value={transfer.senderName}
                onChange={handleChange}
              />

              <input
                type="text"
                name="caption"
                placeholder="توضیحات"
                value={transfer.caption}
                onChange={handleChange}
              />
              <input
                type="text"
                name="price"
                required
                placeholder="مبلغ واریزی "
                value={numberWithCommas(transfer.price)}
                onChange={handleChange}
              />

              <input // Add this input field
                type="file"
                name="image"
                onChange={handleChangeImage}
                multiple
              />

              <div className="row">
                <button type="submit">ثبت</button>
              </div>
            </form>
          </div>
          <div className="overlay"></div>
        </div>
      </div>
    )
  );
}
