import React, { useContext, useEffect, useState, useRef } from "react";
import { GlobalState } from "../../../GlobalState";
import axios from "axios";
import { alertSuccess, alertError } from "../../../services/Alert";
import numberWithCommas from "../../../services/NumbersInRials";

export default function ConfirmDialog({ isVisible, onClose, data, transfer }) {
  const state = useContext(GlobalState);
  const [token] = state.token;
  const [user] = state.UserAPI.user;

  const handleClose = () => {
    onClose();
  };

  const submitDelete = async (e) => {
    e.preventDefault();
    try {
      const successTransferSubmit = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/transfer/delete/${data._id}`,
        {
          headers: { Authorization: token },
        }
      );

      if (successTransferSubmit.data.success) {
        alertSuccess(successTransferSubmit.data.message, 2500);
        window.location.href = "/profile";
      }
    } catch (error) {
      alertError(error.response?.data?.message || "An error occurred", 3000);
    }
  };

  return (
    isVisible && (
      <div className="create-transfer-form-container">
        <div className="modal-container">
          <div className="modal">
            <button onClick={handleClose} className="close-button">
              &times;
            </button>
            <p>
              آیا از حذف حواله به نام{" "}
              <span className="highlight-text">{data.name}</span> اطمینان دارید؟
            </p>
            <p>مانده بدهی: {numberWithCommas(data.price - data.payed)} تومان</p>
            <button
              onClick={submitDelete}
              className="confirm-buttons"
              id="confirm-delete-buttun"
            >
              بله
            </button>
            <button onClick={handleClose}>خیر</button>
          </div>
          <div className="overlay"></div>
        </div>
      </div>
    )
  );
}
