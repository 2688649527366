let numberWithCommas = (x) => {
  if (typeof x !== 'string') {
    x = x.toString();
  }
  const str =  x.replace(/,/g, "");
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

module.exports = numberWithCommas;

