import React from "react";

export default function Footer() {
  return (
    <footer className="footerContainer">
      <div className="footer">
        {/* Quick Links Section */}
        <div className="footer-section">
          <h3>لینک های سریع</h3>
          <ul className="footer-links">
            <li>
              <a href="/signup">ثبت نام</a>
            </li>
            <li>
              <a href="/about">درباره ما</a>
            </li>
            <li>
              <a href="/terms">قوانین و مقررات</a>
            </li>
            <li>
              <a href="/privacy">حریم شخصی</a>
            </li>
            <li>
              <a href="/contact">ارتباط با ما</a>
            </li>
          </ul>
        </div>

        {/* Contact Info Section */}
        <div className="footer-section">
          <h3>اطلاعات تماس</h3>
          <ul className="footer-contact">
            <li>آدرس: تهران، خیابان ...</li>
            <li>تلفن: ۰۲۱-XXXXXXXX</li>
            <li>ایمیل: info@yoursite.com</li>
          </ul>
        </div>

        {/* Certificates Section */}
        <div className="footer-section">
          <h3>مجوزها</h3>
          <div className="footer-certificates">
            {/* Add your certificate images here */}
            <img src="/path/to/enamad" alt="نماد اعتماد الکترونیکی" />
          </div>
        </div>

        {/* Social Media Section */}
        <div className="footer-section">
          <h3>شبکه های اجتماعی</h3>
          <div className="footer-social">
            <a href="#instagram">اینستاگرام</a>
            <a href="#telegram">تلگرام</a>
            <a href="#twitter">توییتر</a>
          </div>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="footer-bottom">
        <p>© {new Date().getFullYear()} تمامی حقوق محفوظ است.</p>
      </div>
    </footer>
  );
}
