import React, { createContext, useState, useEffect } from "react";
import { Axios } from "./components/api/axios";
import UserAPI from "./components/api/UserAPI";
// import TransfersApi from "./components/api/TransfersApi";
import { ToastContainer } from "react-toastify";

export const GlobalState = createContext();

export const DataProvider = ({ children }) => {
  const [token, setToken] = useState(false);

  const refreshToken = async () => {
    const firstLogin = localStorage.getItem("firstLogin");
    if (!firstLogin) return;

    try {
      const res = await Axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/refresh_token`
      );

      if (res.data.success) {
        setToken(res.data.accesstoken);
        setTimeout(() => {
          refreshToken();
        }, 10 * 60 * 1000); // Refresh token every 10 minutes
      }
    } catch (error) {
      localStorage.removeItem("firstLogin");
      if (error.response && error.response.status === 400) {
        console.log("HTTP 400 error occurred:", error.response.data);
        // alertError(error.response.data.message); // Show the error using alertError
      } else {
        console.log("Other error occurred:", error.message);
        // Handle other errors
      }
    }
  };

  useEffect(() => {
    refreshToken();
  }, []);

  const state = {
    token: [token, setToken],
    // TransfersApi: TransfersApi(token),
    UserAPI: UserAPI(token),
  };
  <ToastContainer rtl={true} />;
  return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};
