import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const alertSuccess = (message, time) => {
  toast(message, {
    type: "success",
    autoClose: time,
    transition: Zoom,
    position: "top-center",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    rtl: true,
    fontFamily: "Yekan, Arial",
  });
};

export const alertError = (message, time) => {
  toast.error(message, {
    type: "error",
    autoClose: time,
    transition: Zoom,
    position: "top-center",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    rtl: true,
    fontFamily: "Yekan, Arial",
  });
};
