import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { io } from "socket.io-client";
import { GlobalState } from "../GlobalState";
import { alertSuccess } from "../services/Alert";

const SocketContext = createContext();

export function SocketProvider({ children }) {
  const state = useContext(GlobalState);
  const [user] = state.UserAPI.user;
  const socketRef = useRef(null);
  const [hasAlerted, setHasAlerted] = useState(false);

  useEffect(() => {
    console.log("Setting up socket connection...");

    if (socketRef.current) {
      console.log("Socket already exists, cleaning up...");
      socketRef.current.removeAllListeners();
      socketRef.current.disconnect();
    }

    const socket = io(`${process.env.REACT_APP_BACKEND_URL}`, {
      transports: ["websocket", "polling"],
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });
    socketRef.current = socket;

    socket.on("connect", () => {
      console.log("Socket connected successfully with ID:", socket.id);
    });

    socket.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
    });

    socket.on("disconnect", (reason) => {
      console.log("Socket disconnected:", reason);
    });

    socket.on("transferAction", (data) => {
      console.log("Received transfer action:", data);
      console.log("Current user:", { id: user._id, companyId: user.companyId });

      if (
        data.actorId !== user._id &&
        data.companyId === user.companyId &&
        !hasAlerted
      ) {
        console.log("Conditions met for alert");
        setHasAlerted(true);
        alertSuccess(data.message, 2500);
        setTimeout(() => {
          window.location.reload();
          setHasAlerted(false);
        }, 2500);
      } else {
        console.log("Alert conditions not met:", {
          differentActor: data.actorId !== user._id,
          sameCompany: data.companyId === user.companyId,
          notAlerted: !hasAlerted,
        });
      }
    });

    return () => {
      if (socketRef.current) {
        console.log("Cleaning up socket connection...");
        socketRef.current.removeAllListeners();
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [user._id, user.companyId, hasAlerted]);

  return (
    <SocketContext.Provider value={socketRef.current}>
      {children}
    </SocketContext.Provider>
  );
}

export function useSocket() {
  return useContext(SocketContext);
}
