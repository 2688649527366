import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { alertSuccess, alertError } from "../../../services/Alert";
import Loading from "../../Loading/Loading";
import useFormFields from "../../hooks/FormFields";
import Header from "../../Header/Header";

export default function Register() {
  const [loading, setLoading] = useState(true);

  const { Fields, handleChange } = useFormFields({
    fname: "",
    lname: "",
    email: "",
    address: "",
    mobile: "",
    password: "",
    role: 512
  });

  // show loading
  useEffect(() => {
    const ldData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 400));
      setLoading((loading) => !loading);
    };
    ldData();
  }, []);

  const registerSubmit = async (e) => {
    e.preventDefault();
    try {
      const successRegister = await axios.post(
        "http://localhost:4001/api/v1/user/register",
        {
          ...Fields,
        }
      );
      if (successRegister) {
        localStorage.setItem("firstLogin", true);
        alertSuccess(successRegister.data.message, 2800);
        setTimeout(() => {
          window.location.href = "/";
        }, 2500);
      }
    } catch (error) {
      alertError(error.response.data.message, 4000);
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <div className="login-page">
            <form onSubmit={registerSubmit}>
              <h2>ثبت نام</h2>
              <input
                type="text"
                name="fname"
                required
                placeholder="نام خود خود را وارد کنید"
                value={Fields.fname}
                onChange={handleChange}
              />

              <input
                type="text"
                name="lname"
                required
                placeholder="نام خانوادگی خود را وارد کنید"
                value={Fields.lname}
                onChange={handleChange}
              />

              <input
                type="email"
                name="email"
                required
                placeholder="ایمیل خود را وارد کنید"
                value={Fields.email}
                onChange={handleChange}
              />

              <input
                type="text"
                name="address"
                required
                placeholder="آدرس خود را وارد کنید"
                value={Fields.address}
                onChange={handleChange}
              />

              <input
                type="text"
                name="mobile"
                required
                placeholder="شماره تلفن خود را وارد کنید"
                value={Fields.mobile}
                onChange={handleChange}
              />

              <input
                type="password"
                name="password"
                required
                placeholder="رمز عبور خود را وارد کنید"
                value={Fields.password}
                onChange={handleChange}
              />

              <div className="row">
                <button type="submit">ثبت نام</button>
                <Link to="/login">ورورد</Link>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
