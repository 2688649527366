import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { DataProvider } from "./GlobalState";
import MainPages from "./components/Pages/Pages";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import { SocketProvider } from "./context/SocketContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
// require('dotenv').config()

// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

// Create RTL theme
const theme = createTheme({
  direction: "rtl",
  typography: {
    fontFamily: "Yekan, Arial", // Replace with your preferred Persian font
  },
});

function App() {
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <DataProvider>
          <SocketProvider>
            <Router>
              <div className="App">
                {/* <Header /> */}
                <MainPages />
                {/* <Footer /> */}
              </div>
            </Router>
          </SocketProvider>
        </DataProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}
export default App;
