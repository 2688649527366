import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import TransferCreate from "./Transfer/Create";
import AddAction from "./Transfer/AddAction";
import AddCollegue from "./Transfer/AddCollegue";
import UserPanel from "./Transfer/UserPanel";
import AdminPanel from "./Profile/ProfileAdmin";
import Settings from "./Profile/Settings";
import DetailProduct from "./DetailProduct/DetailProduct";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import Cart from "./Cart/Cart";
import Checkout from "./Checkout/Checkout";
import NotFound from "./utils/NotFound/NotFound";
import History from "./utils/History/History";
import PaymentSuccess from "./utils/Pyament/PaymentSuccess";
import PaymentFail from "./utils/Pyament/PaymentFail";
import Profile from "./Profile/Profile";
import { GlobalState } from "../../GlobalState";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { ToastContainer } from "react-toastify";

export default function Pages() {
  const state = useContext(GlobalState);
  const [isLogged] = state.UserAPI.isLogged;
  const [isAdmin] = state.UserAPI.isAdmin;

  return (
    <>
      {isLogged && <Header />}
      <Routes>
        <Route exact path="/" element={isLogged ? <Profile /> : <Login />} />
        <Route
          exact
          path="/create_transfer"
          element={isLogged ? <TransferCreate /> : <Login />}
        />
        <Route
          exact
          path="/add_collegue"
          element={isLogged ? <AddCollegue /> : <Login />}
        />
        <Route
          exact
          path="/user_panel"
          element={isLogged ? <UserPanel /> : <Login />}
        />
        <Route
          exact
          path="/settings"
          element={isLogged ? <Settings /> : <Login />}
        />
        <Route
          exact
          path="/profile/add-action"
          element={isLogged ? <AddAction /> : <Login />}
        />

        <Route
          exact
          path="/login"
          element={isLogged ? <NotFound /> : <Login />}
        />

        <Route
          exact
          path="/register"
          element={isAdmin ? <Register /> : <Login />}
        />

        <Route exact path="/profile" element={<Profile />} />

        <Route exact path="*" element={<NotFound />} />
      </Routes>
      {isLogged && <Footer />}

      <ToastContainer rtl={true} />
    </>
  );
}
